import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import React, { useEffect } from "react";

const mapsKey = process.env.REACT_APP_MAPS_API_KEY;
// const mapsKey = process.env.REACT_APP_MAPS_API_KEY_TEST; // to see the map when testing locally

function NewMapComponent(props) {
  const style = {
    // width: props.size,
    // height: props.size,
    borderTop: "0px",
    borderColor: "black",
    borderStyle: "solid",
  };

  const style2 = {
    width: props.size,
    height: props.size,
  };

  return (
    <div className="wrapper-div">
      <div
        className="map-div"
        // style={style2}
      >
        <Map
          className="map-div-inner"
          google={props.google}
          zoom={10}
          center={{
            // lat: airportCoords.xcor,
            // lng: airportCoords.ycor,
            lat: props.latitude_ns,
            lng: props.longitude_ew,
          }}
          initialCenter={{
            // lat: airportCoords.ycor,
            // lng: airportCoords.xcor,
            lat: props.latitude_ns,
            lng: props.longitude_ew,
          }}
          style={style}
        >
          <Marker
            position={{
              // lat: airportCoords.xcor,
              // lng: airportCoords.ycor,
              lat: props.latitude_ns,
              lng: props.longitude_ew,
            }}
          />
        </Map>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: mapsKey,
})(NewMapComponent);
